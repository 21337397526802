<template>
  <div>
    <h2>Practitioner Calendar</h2>
    <div class="filters">
      <a-select
        v-model="selectedLocations"
        placeholder="States"
        mode="multiple"
        style="width: 300px"
        optionFilterProp="children"
        :allowClear="true"
        :options="locations.map((t) => ({ label: t.name, value: t.id }))"
      />
      <a-select
        v-model="selectedPractitioners"
        placeholder="Practitioners"
        mode="multiple"
        style="width: 500px"
        optionFilterProp="children"
        :allowClear="true"
        :options="practitioners.map((t) => ({ label: t.name, value: t.id }))"
      />
      <a-select
        v-model="calendarOptions.timeZone"
        placeholder="TimeZone"
        optionFilterProp="children"
        :options="
          ['', ...timeZones].map((t) => ({ label: t || '(Default)', value: t }))
        "
      />
    </div>
    <FullCalendar
      ref="fullCalendar"
      class="calendar"
      :options="calendarOptions"
      v-if="showCalendar"
    />
  </div>
</template>

<script>
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment-timezone'
const Color = require('color')
import tippy from 'tippy.js'
import { queryCalendarData } from '@/api/appointment'

const statusColorMap = {
  //'Canceled': '#ff9900',
  'Missed': '#666',
  'FreePassed': '#666',
  'Confirmed': '#2d8cf0',
  'ConfirmedPassed': '#19be6b',
  'Canceled': '#ed4014',
  'Free': 'orange'
}
const getServiceColor = (color, name) => {
  if (!name) return color
  name = name.toLowerCase()
  if (name.startsWith('initial')) {
    return Color(color).darken(0.25)
  }
  if (name.startsWith('follow-up')) {
    return color
  }
  return Color(color).lighten(0.25)
}
const appointmentToCalendarEvent = (appointment, tz) => {
  const now = moment.utc()
  let { start_date, duration, status, service_name } = appointment
  // console.log(start_date, moment(start_date))
  start_date = moment.utc(start_date)
  if (start_date.minute() !== 0 && start_date.minute() !== 30) {
    start_date.minute(Math.floor(start_date.minute() / 30) * 30)
    // if (status === 'Free') {
    //   var minute = start_date.minute()
    //   start_date.minute(Math.floor(minute / 15) * 15)
    //   duration = 15
    // }
  }
  let end_date = start_date.clone().add(30, 'minutes')
  let color = statusColorMap[status + (now.isAfter(end_date) && 'Passed' || '')] || statusColorMap[status]
  color = getServiceColor(color, service_name)
  return {
    start: (tz && start_date.tz(tz) || start_date.local()).format('YYYY-MM-DD HH:mm'),
    end: (tz && end_date.tz(tz) || end_date.local()).format('YYYY-MM-DD HH:mm'),
    title: service_name,
    color,
    extendedProps: { appointment }
  }
}

const getGroupedAppointments2 = (appointments) => {
  return appointments.reduce((m, a) => {
    let time = moment.utc(a.start_date)
    if (time.minute() !== 0 && time.minute() !== 30) {
      time.minute(Math.floor(time.minute() / 30) * 30)
    }
    time = time.valueOf()
    if (!m[time]) {
      m[time] = [a]
      return m
    }
    if (a.status === 'Free') {
      if (!m[time].filter(t => t.practitioner_id === a.practitioner_id).length) {
        m[time].push(a)
      }
    } else {
      m[time] = m[time].filter(t => t.practitioner_id !== a.practitioner_id || t.status !== 'Free')
      m[time].push(a)
    }
    return m
  }, {})
}

const getGroupedAppointments = (appointments) => {
  // Free排在最后
  return appointments.sort((a, b) => b.status === 'Free' ? -1 : 0).reduce((m, a) => {
    let time = moment.utc(a.start_date)
    if (time.minute() !== 0 && time.minute() !== 30) {
      time.minute(Math.floor(time.minute() / 30) * 30)
    }
    time = time.valueOf()
    if (!m[time]) {
      m[time] = [a]
      return m
    }
    // m[time].push(a)
    // return m
    const pm = m[time].filter(t => t.practitioner_id === a.practitioner_id)
    if (!pm.length) {
      // 没有practitioner的appointment,直接加入
      m[time].push(a)
      return m
    }

    if (a.status === 'Free') {
      // （这里有其他项存在）Free的直接忽略
    } else if (a.status === 'Canceled') {
      // （这里有其他项存在）Cancel的直接忽略
    } else {
      // 如果是其他(Missed/Confirmed)状态，删除同一个时间段内Canceled
      if (pm.find(t => t.status === 'Canceled')) {
        m[time] = [...m[time].filter(t => t.practitioner_id !== a.practitioner_id || t.status !== 'Canceled'), a]
      } else {
        m[time].push(a)
      }
    }
    return m
  }, {})
}

const generateEvents = (appointments, tz) => {
  let events = Object.values(getGroupedAppointments(appointments)).flat().sort((a, b) => {
    if (a.practitioner_name !== b.practitioner_name) return a.practitioner_name.localeCompare(b.practitioner_name)
    const statusOrder = { Free: 0, Canceled: 10, Missed: 20, Confirmed: 30, WaitingConfirm: 40 }
    return (statusOrder[a.status] || 100) - (statusOrder[b.status] || 100)
  }).map(a => appointmentToCalendarEvent(a, tz))
  let slotEvents = events.reduce((m, e) => {
    (m[e.start] = m[e.start] || []).push(e)
    return m
  }, {})
  Object.keys(slotEvents).forEach(s => {
    const events = slotEvents[s]
    if (events.length >= 8) return
    events.push(...new Array(8 - events.length).fill(0).map(t => ({
      ...events[0],
      color: 'transparent',
      title: ''
    })))
  })
  return Object.values(slotEvents).flat()
}

export default {
  components: { FullCalendar },
  watch: {
    selectedLocations () {
      this.refetchEvents()
    },
    selectedPractitioners () {
      this.refetchEvents()
    },
    "calendarOptions.timeZone" (tz) {
      // workaround to update timezone for nowIndicator not updating issue
      this.showCalendar = false
      this.$nextTick(() => this.showCalendar = true)
    }
  },
  // computed: {
  //   selectedLocations () {
  //     return this.selectedLocation && [this.selectedLocation] || []
  //   }
  // },
  data () {
    return {
      showCalendar: true,
      locations: [],
      selectedLocations: [],
      practitioners: [],
      selectedPractitioners: [],
      timeZones: moment.tz.names(),
      calendarOptions: {
        plugins: [momentTimezonePlugin, dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: 'timeGridWeek',
        height: 1300,
        nowIndicator: true,
        timeZone: moment.tz.guess(),
        allDaySlot: false,
        slotDuration: '0:30:00',
        slotEventOverlap: false,
        eventOrder: (a, b) => 1,
        //eventMaxStack: 1,
        // slotMinTime: '9:00',
        // slotMaxTime: '18:00',
        // businessHours: {
        //   // days of week. an array of zero-based day of week integers (0=Sunday)
        //   daysOfWeek: [1, 2, 3, 4, 5, 6, 0],
        //   startTime: '8:00', // a start time (10am in this example)
        //   endTime: '21:00', // an end time (6pm in this example)
        // },
        events: this.handleGetEvents,
        displayEventTime: false,
        eventContent: this.handleEventContent,
        eventDidMount: this.handleEventMount,
        eventMouseEnter: this.handleEventContentPopup,
        eventMouseLeave: null,
      }
    }
  },
  mounted () {
  },
  methods: {
    async handleGetEvents ({ start, end, timeZone }, onSuccess, onFailure) {
      const { appointments, practitioners, locations } = await queryCalendarData({ dateStart: start, dateEnd: end, practitionerIds: this.selectedPractitioners, locationIds: this.selectedLocations })
      const events = generateEvents(appointments, this.calendarOptions.timeZone)
      onSuccess(events)
      this.locations = locations
      this.practitioners = practitioners
    },
    handleEventContent (arg, h) {
      const { appointment } = arg.event.extendedProps || {}
      if (!appointment) return null
      const { start_date, duration, status, service_name, practitioner_name } = appointment
      const name = practitioner_name.split(',')[0].split(' ').map(t => t[0]).filter((v, i) => i < 2).join('')
      return h('span', null, name)
    },
    handleEventMount ({ el, event: { extendedProps: { appointment } } }) {
      if (!appointment) return null
      const { start_date, duration, status, service_name, practitioner_name } = appointment
      const timeString = (this.calendarOptions.timeZone && moment.utc(start_date).tz(this.calendarOptions.timeZone) || moment.utc(start_date).local()).format('YYYY-MM-DD HH:mm') + ` (${duration}min)`
      tippy(el, {
        allowHTML: true,
        content: `<div><b>${timeString}</b></div><div>${status}</div><div>${service_name}</div><div>${practitioner_name}</div>`
      })
    },
    handleEventContentPopup (e) {
      //console.log(e)
    },
    refetchEvents () {
      let api = this.$refs.fullCalendar.getApi()
      api.refetchEvents()
      api.render()
    }
  }
}
</script>

<style lang="scss">
.calendar {
  --fc-small-font-size: 0.7em;

  .fc-timegrid-event-harness-inset .fc-timegrid-event,
  .fc-timegrid-event.fc-event-mirror,
  .fc-timegrid-more-link {
    box-shadow: none;
    margin: 1px 1px 1px 0;
    cursor: pointer;
  }
}

.filters {
  > * {
    margin: 0.5em;
  }
}
</style>
